import AreaChartStandard from '../components/AreaChartStandard';
import Sidebar from '../components/Sidebar';
import LineChartStandard from '../components/LineChartStandard';
import AssumptionCard from '../components/AssumptionCard';
import CalculationBox from '../components/CalculationBox';
import FetchingData from '../components/FetchingData';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context';

export default function Count() {
    const { user } = useContext(UserContext)
    const [userData, setUserData] = useState()
    const [countData, setCountData] = useState()

    useEffect(() => {
        const response = FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
        response.then(res => {
            if (!res.error){
                setUserData(res)
            } else {
                console.log(res.error)
            }
        })
        const response2 = FetchingData(`/api/count?organizationId=${user.organization_id}`, undefined, 'GET')
        response2.then(res => {
            if (!res.error){
                setCountData(res)
            } else {
                console.log(res.error)
            }
        })
    }, [])

  return (
    <>
        {
            userData ? 
                <>
                <div className="flex flex-col bg-gray-700">
                <Sidebar/>
                <main className="flex-grow ml-48 relative h-screen">
                    <section className='p-8 bg-gray-700'>
                        <div className="w-full flex flex-col bg-gray-700">
                            <h1 className="text-2xl text-white mb-8">Results</h1>
                            <div className="flex w-full gap-2 mb-4">
                                <CalculationBox
                                    title='Inventory Count'
                                    children={[
                                        {
                                            title: 'Physical counts per year',
                                            calculation: [],
                                            result: userData?.physical_counts ?? 'N/A'
                                        },
                                        {
                                            title: 'Hours saved',
                                            calculation: [userData?.physical_counts ?? 'N/A', 'x', userData?.hours_per_physical_counts ?? 'N/A', 'x', userData?.active_stores ?? 'N/A'],
                                            result: parseFloat(userData?.physical_counts ?? 0) * parseFloat(userData?.hours_per_physical_counts ?? 0) * parseFloat(userData?.active_stores ?? 0)
                                        },
                                        {
                                            title: 'Money Saved',
                                            calculation: [parseFloat(userData?.physical_counts ?? 0) * parseFloat(userData?.hours_per_physical_counts ?? 0) * parseFloat(userData?.active_stores ?? 0) , 'x', userData?.price_per_working_hour ?? 'N/A'],
                                            result: ((parseFloat(userData?.physical_counts ?? 0) * parseFloat(userData?.hours_per_physical_counts ?? 0) * parseFloat(userData?.active_stores ?? 0)) * userData?.price_per_working_hour ?? 'N/A').toFixed(0)
                                        }
                                ]} />
                                <div className="w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                                    <AreaChartStandard data={countData} userData={userData} type={'count'} height={'100%'} />
                                </div>
                            </div>
                        </div>
                        <h1 className="text-xl font-bold text-white mb-8">Assumptions</h1>
                        <div className="flex flex-wrap gap-2 bg-gray-700">
                            <AssumptionCard title='Physical counts per year' children={{value: userData.physical_counts ?? 'N/A', units: ' / counts', description: 'Current physical inventory counts per year' }} />
                            <AssumptionCard title='Hours spent' children={{value: userData.hours_per_physical_counts ?? 'N/A', units: ' / hours', description: 'Hours spent on physical inventory per store per year' }} />
                            <AssumptionCard title='Active Stores' children={{value: userData.active_stores ?? 'N/A', units: ' / stores', description: 'Number of stores active (Springfield + Springfield int.)' }} />
                            <AssumptionCard title='Spent in scanners' children={{value: userData.spent_in_scanners ?? 'N/A', units: ' / €', description: 'Money saved by not having to invest in scanners' }} />
                            <AssumptionCard title='Spent in software' children={{value: userData.spent_in_software ?? 'N/A', units: ' / €', description: 'Money saved by not having to invest in software to run the scanners' }} />
                            <AssumptionCard title='Close Stores' children={{value: userData.spent_in_close_stores ?? 'N/A', units: ' / €', description: 'Money saved by not having to close down stores to run the count' }} />
                            <AssumptionCard title='Price per hour' children={{value: userData.price_per_working_hour ?? 'N/A', units: ' / €', description: 'Average price per hour' }} />
                        </div>
                    </section>
                </main>
                </div>
                </>
                :
                <>
                <div className="flex flex-col bg-gray-700 w-full h-screen">
                <Sidebar/>
                </div>
                </>

        }
    </>
  )
}