import { useContext, useState } from "react";
import { UserContext } from "./Context";
import { useNavigate } from 'react-router-dom';
import FetchingData from "./components/FetchingData";


export default function App() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState()
  const navigate = useNavigate();
  const { toggleUser } = useContext(UserContext);

  const handleClick  = async (event) => {
    event.preventDefault();
    console.log('click login')
    const response = await FetchingData("/api/login", {username: username, password: password}, "POST")
    console.log('login response',response)
    if (!response?.error) {
      const response2 = await FetchingData('/api/retrieve-scope', undefined, 'GET')
      console.log('get scope response', response2)
      if (!response2.error) {
        toggleUser(response2.organization[0]?.organization_id, response2?.user?.role, response2.organization)
        navigate('/home')
      }
    }
    else {
      setError({error: response?.error, status: response?.status})
    }
  }

  const handleChangeUser = (event) => {
      setUsername(event.target.value)
  }

  const handleChangePass = (event) => {
      setPassword(event.target.value)
  }

  return (
    <>
      <main className="bg-gray-700 h-full">
        <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
        <form className="md:w-1/3 max-w-sm" onSubmit={handleClick}>
          <input
            onChange={handleChangeUser}
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
            type="email"
            autoComplete="email"
            placeholder="Username"
            required
          />
          <input
            onChange={handleChangePass}
            className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            required
          />
          {
              <div>
                  <p className="text-red-400">{error?.error}</p>
              </div>
          }
          <div className="text-center md:text-left">
            <button
              type="submit"
              className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
            >
              Login
            </button>
          </div>
        </form>
      </section>
      </main>
    </>
  );
}
