import { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
// get Week of Year from dayjs
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

export default function AreaChartStandard(props) {
    const [ usableData, setUsableData] = useState()
    const { data, userData, type,  height, bestworstConversionRate } = props;
    
    useEffect(() => {
      if (data && userData) {
        let reducedata = []
        if (type === 'receiving') {
          reducedata = data.map((item) => {
            return {
              week: dayjs(item.wks_start_date).week(),
              value: Math.round((parseFloat(item.boxes ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0))) / 3600) * parseFloat(userData?.price_per_working_hour ?? 0))
          }})
        reducedata.sort((a, b) => a.week - b.week)
        } else if (type === 'count') {
        reducedata = data.map((item) => {
          return {
            week: dayjs(item.week_start_date).week(),
            value: Math.round((parseFloat(userData.physical_counts) * parseFloat(userData.hours_per_physical_counts) * parseFloat(userData.active_stores)) * parseFloat(userData.price_per_working_hour)),
          }})
          reducedata.sort((a, b) => a.week - b.week)
        } else if (type === 'onshelf') {
          reducedata = data.map((item) => {
            return {
              week: dayjs(item.week_start_date).week(),
              value: Math.round((parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0)) * (parseFloat(userData?.osa_sales_uplift ?? 0)/100)),
            }})
          reducedata.sort((a, b) => a.week - b.week)
        } else if (type === 'critical') {
        reducedata = data.map((item) => {
          return {
            week: dayjs(item.week_start_date).week(),
            value: Math.round(parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0) * ( parseFloat(userData?.critical_sales_uplift ?? 0) / 100)),
          }})
          reducedata.sort((a, b) => a.week - b.week)
        } else if (type === 'conversion') {
          reducedata = data.map((item) => {
            return {
              week: dayjs(item.date).format('DD/MM/YYYY'),
              value: item.conversion,
            }
          })
          console.log('reducedata',reducedata)
          reducedata.sort((a, b) => a.week - b.week)
        }

        //console.log('reducedata',reducedata)
        setUsableData(reducedata)
      }
    }, [data, userData])
    
    if (!data && !userData) return (
      <div className="w-full flex justify-center h-60 items-center">
        <p className="w-1/2 rounded text-xs text-center border border-[#82ca9d] text-[#82ca9d] font-bold">NO DATA</p>
      </div> 
  )
    if ((type === 'critical' || type === 'onshelf' || type === 'count') && userData?.length !== 0){
      let styleHeight = height
      if (height === '40%') {
        styleHeight = '2/5'
      } else {
        styleHeight = 'full'
      }
      return (
        <div className={`w-full h-${styleHeight} py-10 flex justify-center items-center`}>
          <p className='w-1/2 rounded text-xs text-center border border-[#82ca9d] text-[#82ca9d] font-bold'>BUSINESS CASE EXTRAPOLATION</p>
        </div>
    )}

    return (
      <>
        {
          data ? (
            <>
              <ResponsiveContainer width="95%" height={height} >
                <AreaChart data={usableData}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="week" />
                  <YAxis tickFormatter={(value) =>
                          new Intl.NumberFormat("en-US", {
                            notation: "compact",
                            compactDisplay: "short",
                          }).format(value)
                        } type='number' domain={[0,'dataMax']}/>
                  <Tooltip />
                  <Area type="monotone" dataKey="value" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                </AreaChart>
              </ResponsiveContainer>
            </>
          ) :
          data && type === 'conversion' ? (
            <>
            <ResponsiveContainer width="95%" height={height} >
              <AreaChart data={usableData}
                margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="week" />
                <YAxis tickFormatter={(value) =>
                        new Intl.NumberFormat("en-US", {
                          notation: "compact",
                          compactDisplay: "short",
                        }).format(value)
                      } type='number' domain={[0,'dataMax']}/>
                <Tooltip />
                <Area type="monotone" dataKey="value" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                <Area type="monotone" dataKey="bw" stroke="#ff0000" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </>
          ) :
          (
            <p>No data</p>
          )
        }
      </>
      )
  } 