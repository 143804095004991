import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../Context";
import { useContext } from 'react'

export default function AddUser() {
    const navigate = useNavigate();
    const [token, setToken] = useState('')
    const [error, setError] = useState()
    const { user } = useContext(UserContext);

    const handleClick = async (token) => {
        try{
            //const response = await fetch(`http://localhost:3000/api/create-organization`,
            const response = await fetch(`/api/create-organization`,
                {
                    credentials: "include",
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    method: "POST",
                    body: JSON.stringify({token: token})
                })
            if (!response.ok){
                const resp = await response.text()
                console.log(resp)
                setError(`${response.statusText}: ${resp}`)
            } else {
                const resp = await response.json()
                console.log(resp)
                navigate('/home')
            }
        } catch (error){
            setError(error.statusText)
        }
    }

    const handleChangePass = (event) => {
        setToken(event.target.value)
    }

    return (
        <>
        {
            user ? (            
                <>
                <div className="flex bg-gray-700">
                    <Sidebar />
                    <main className="flex-grow ml-48 relative">
                        <div className="w-full h-screen flex flex-col items-center justify-center pr-8">
                            <section className="flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
                                <div className="w-full">
                                    <input
                                    onChange={handleChangePass}
                                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                                    placeholder="Token"
                                    />
                                    {
                                        <div>
                                            <p className="text-red-400">{error}</p>
                                        </div>
                                    }
                                    <div className="text-center md:text-left">
                                    <button
                                        onClick={() => handleClick(token)}
                                        className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
                                        type="submit"
                                    >
                                        Create
                                    </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                </div>
                </>
            ) : (
                <div className="flex bg-gray-700 w-full h-screen place-content-center">
                    <p className="text-white text-lg m-auto">Loading</p>
                </div>
            )
        }
    </>
    )
}