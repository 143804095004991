import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../Context";
import { useContext } from 'react'
import ListUsers from "../components/ListUsers";
import FetchingData from "../components/FetchingData";

export default function AddUser() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState()
    const [listUsers, setListUsers] = useState([])
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user) {
            const response = FetchingData(`/api/retrieve-all?organizationId=${user.organization_id}`, undefined, 'GET')
            response.then(res => {
                if (!res.error){
                    setListUsers(res)
                } else {
                    console.log(res.error)
                }
            })
        }
    }, [])


    const handleClick = async (user) => {
        const response = await FetchingData('/api/signup', {username: username, password: password, scope: user.organization_id}, 'POST')
        if (!response.error){
            navigate('/home')
        } else {
            setError(response.error)
    }
    }

    const handleChangeUser = (event) => {
        setUsername(event.target.value)
    }

    const handleChangePass = (event) => {
        setPassword(event.target.value)
    }

    return (
        <>
        {
            user ? (            
                <>
                <div className="flex bg-gray-700">
                    <Sidebar />
                    <main className="flex-grow ml-48 relative">
                        <div className="w-full h-screen flex flex-col items-center justify-center pr-8">
                            <h1 className="text-lg text-white mb-4">List Users</h1>
                            <section className="p-2 m-4">
                                    <ListUsers listUsers={listUsers} />
                            </section>
                            <h1 className="text-lg text-white mb-4">Create a new User</h1>
                            <section className="flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
                                <div className="w-full">
                                    <input
                                    onChange={handleChangeUser}
                                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
                                    type="text"
                                    placeholder="Username"
                                    />
                                    <input
                                    onChange={handleChangePass}
                                    className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                                    type="password"
                                    placeholder="Password"
                                    />
                                    {
                                        <div>
                                            <p className="text-red-400">{error}</p>
                                        </div>
                                    }
                                    <div className="text-center md:text-left">
                                    <button
                                        onClick={() => handleClick(user)}
                                        className="mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
                                        type="submit"
                                    >
                                        Create
                                    </button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                </div>
                </>
            ) : (
                <div className="flex bg-gray-700 w-full h-screen place-content-center">
                    <p className="text-white text-lg m-auto">Loading</p>
                </div>
            )
        }
    </>
    )
}