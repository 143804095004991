import Sidebar from '../components/Sidebar';

export default function NotFound() {
  return (
    <>
    <div className="flex flex-col bg-gray-700">
    <Sidebar/>
    <main className="flex-grow ml-48 relative h-screen">
        <div className='flex w-full h-full justify-center items-center'>
            <h1 className="text-2xl text-white mb-8 align-baseline">404 Not Found</h1>
        </div>
    </main>
    </div>
    </>
  )
}