import { LineChart, XAxis, YAxis, Line, Tooltip, Legend, ResponsiveContainer } from "recharts";

export default function LineChartStandard(props){

  const { data, type } = props;

  let subtype = null
  let processedData = []

  if (!data) return (
    <div className="w-full flex justify-center h-40 items-center">
      <p className="w-1/2 rounded text-xs text-center border border-[#82ca9d] text-[#82ca9d] font-bold">NO DATA</p>
    </div> 
 )

  if (data[0] && type === 'osa') {
    processedData = Object.keys(data[0]).map((key) => {
      let date = new Date()
      if (key === 'average_osa_year'){
        date = date.setFullYear(date.getFullYear() - 1)
        return {
          date: new Date(date).toDateString(),
          osa: (data[0][key] * 100).toFixed(2)
        }
      } else if (key === 'average_osa_5_weeks'){
        date = date.setDate(date.getDate() - 35)
        return {
          date: new Date(date).toDateString(),
          osa: (data[0][key] * 100).toFixed(2)
        }
      } else if (key === 'average_osa_3_weeks'){
        date = date.setDate(date.getDate() - 21)
        return {
          date: new Date(date).toDateString(),
          osa: (data[0][key] * 100).toFixed(2)
        }
      } else if (key === 'average_osa_2_week'){
        date = date.setDate(date.getDate() - 14)
        return {
          date: new Date(date).toDateString(),
          osa: (data[0][key] * 100).toFixed(2)
        }
      } else {
        return undefined
      }
    })
    processedData = processedData.filter((item) => item !== undefined)
  }

  if (data && type === 'sales') {
    processedData = data.reduce((result, item) => {
      const { month_name, store, total_sales } = item;
      const existingMonth = result.find((entry) => entry.month === month_name);
      if (existingMonth) {
        existingMonth[store] = Math.round(total_sales);
      } else {
        const newMonth = { month: month_name };
        newMonth[store] = Math.round(total_sales);
        result.push(newMonth);
      }
      return result;
    }, [])
  }

  
  if (data && type === 'traffic') {
    processedData = data.reduce((result, item) => {
      const { month_name, store, total_count } = item;
      const existingMonth = result.find((entry) => entry.month === month_name);
      if (existingMonth) {
        existingMonth[store] = Math.round(total_count);
      } else {
        const newMonth = { month: month_name };
        newMonth[store] = Math.round(total_count);
        result.push(newMonth);
      }
      return result;
    }, []);
  }
  if (!data[1] && type === 'traffic') {
    subtype = 'average'
    processedData = Object.keys(data[0]).map((key) => {
      let date = new Date()
      if (key === 'average_count_year'){
        date = date.setFullYear(date.getFullYear() - 1)
        return {
          date: new Date(date).toDateString(),
          count: Math.round(data[0][key] ?? 0)
        }
      } else if (key === 'average_count_5_weeks'){
        date = date.setDate(date.getDate() - 35)
        return {
          date: new Date(date).toDateString(),
          count: Math.round(data[0][key] ?? 0)
        }
      } else if (key === 'average_count_3_weeks'){
        date = date.setDate(date.getDate() - 21)
        return {
          date: new Date(date).toDateString(),
          count: Math.round(data[0][key] ?? 0)
        }
      } else if (key === 'average_count_2_week'){
        date = date.setDate(date.getDate() - 14)
        return {
          date: new Date(date).toDateString(),
          count: Math.round(data[0][key] ?? 0)
        }
      } else {
        return undefined
      }
    })
    processedData = processedData.filter((item) => item !== undefined)
  }
  

  if (data[0] && !data[1] && type === 'sales') {
    subtype = 'average'

    processedData = Object.keys(data[0]).map((key) => {
      let date = new Date()
      if (key === 'average_sold_year'){
        date = date.setFullYear(date.getFullYear() - 1)
        return {
          date: new Date(date).toDateString(),
          sold: Math.round(data[0][key] ?? 0)
        }
      } else if (key === 'average_sold_5_weeks'){
        date = date.setDate(date.getDate() - 35)
        return {
          date: new Date(date).toDateString(),
          sold: Math.round(data[0][key] ?? 0)
        }
      } else if (key === 'average_sold_3_weeks'){
        date = date.setDate(date.getDate() - 21)
        return {
          date: new Date(date).toDateString(),
          sold: Math.round(data[0][key] ?? 0)
        }
      } else if (key === 'average_sold_2_week'){
        date = date.setDate(date.getDate() - 14)
        return {
          date: new Date(date).toDateString(),
          sold: Math.round(data[0][key] ?? 0)
        }
      } else {
        return undefined
      }
    })
    processedData = processedData.filter((item) => item !== undefined)
  }

  // create a list of 10 hex colors to use for the lines
  const colors = [
    '#00FF00', '#33FF33', '#66FF66', '#99FF99', '#CCFFCC',
    '#FF9933', '#FF3366', '#FF9999', '#FF9999', '#FF4D4D'
  ];

  if (processedData.length === 0) return (
    <div className="w-full flex justify-center h-40 items-center">
      <p className="w-1/2 rounded text-xs text-center border border-[#82ca9d] text-[#82ca9d] font-bold">NO DATA</p>
    </div> 
  )

    return (
        <>
        {
          type === 'osa' &&
          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              data={processedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              >
              <XAxis dataKey="date"/>
              <YAxis domain={['dataMin - 1', 100]}/>
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="osa" stroke="#82ca9d" strokeWidth={3}/>
            </LineChart>
          </ResponsiveContainer>
        }
        {
          type === 'sales' && subtype === null &&

          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              data={processedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              >
              <XAxis dataKey="month"/>
              <YAxis domain={[0, 'dataMax + 1000']}/>
              <Tooltip />
              {
                Object.keys(processedData[0]).map((key, index) => {
                  if (key !== 'month') {
                    return <Line key={index} type="monotone" dataKey={key} stroke={colors[index]} strokeWidth={1}/>
                  }
                })
              }
            </LineChart>
          </ResponsiveContainer>
        }
        {
          type === 'traffic' && subtype === null &&
          <>
          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              data={processedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              >
              <XAxis dataKey="month"/>
              <YAxis domain={[0, 'dataMax + 1000']}/>
              <Tooltip />
              {
                Object.keys(processedData[0]).map((key, index) => {
                  if (key !== 'month') {
                    return <Line key={index} type="monotone" dataKey={key} stroke={colors[index]} strokeWidth={1}/>
                  }
                })
              }
            </LineChart>
          </ResponsiveContainer>
          </>
        }
        {
          type === 'sales' && subtype === 'average' &&
          <>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart
                data={processedData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                >
                <XAxis dataKey="date"/>
                <YAxis domain={[0, 'dataMax']}/>
                <Tooltip />
                <Line type="monotone" dataKey="sold" stroke="#82ca9d" strokeWidth={3}/>
              </LineChart>
            </ResponsiveContainer>
          </>
        }        
        {
          type === 'traffic' && subtype === 'average' &&
          <>
            <ResponsiveContainer width="100%" height="80%">
              <LineChart
                data={processedData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                >
                <XAxis dataKey="date"/>
                <YAxis domain={[0, 'dataMax']}/>
                <Tooltip />
                <Line type="monotone" dataKey="count" stroke="#82ca9d" strokeWidth={3}/>
              </LineChart>
            </ResponsiveContainer>
          </>
        }

        </>
      );
  };