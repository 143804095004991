import { useEffect, useState } from 'react';
import { AreaChart, Area, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
// get Week of Year from dayjs
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

export default function AreaChartOsa(props) {
    const { data, type} = props;
    let processedData = []
      if (data && type === 'osa') {
        console.log('osa',data)
        const bestData = data.best
        const worstData = data.worst
  
        if (bestData && worstData) {
  
          const worstOSAyear = worstData.reduce((total, next) => total + parseFloat(next.average_osa_year ) , 0) / worstData.length
          const bestOSAyear = bestData.reduce((total, next) => total + parseFloat(next.average_osa_year ), 0) / bestData.length
          const worstOSA5weeks = worstData.reduce((total, next) => total + parseFloat(next.average_osa_5_weeks ?? worstOSAyear), 0) / worstData.length
          const bestOSA5weeks = bestData.reduce((total, next) => total + parseFloat(next.average_osa_5_weeks ?? bestOSAyear), 0) / bestData.length
          const worstOSA3weeks = worstData.reduce((total, next) => total + parseFloat(next.average_osa_3_weeks ?? worstOSA5weeks), 0) / worstData.length
          const bestOSA3weeks = bestData.reduce((total, next) => total + parseFloat(next.average_osa_3_weeks ?? bestOSA5weeks), 0) / bestData.length
          const worstOSA2weeks = worstData.reduce((total, next) => total + parseFloat(next.average_osa_2_week ?? worstOSA3weeks), 0) / worstData.length
          const bestOSA2weeks = bestData.reduce((total, next) => total + parseFloat(next.average_osa_2_week ?? bestOSA3weeks), 0) / bestData.length
  
          processedData.push({
            date: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toDateString(),
            osa: [ worstOSAyear * 100, bestOSAyear * 100]
          })
          processedData.push({
            date: new Date(new Date().setDate(new Date().getDate() - 35)).toDateString(),
            osa: [worstOSA5weeks * 100, bestOSA5weeks * 100 ]
          })
          processedData.push({
            date: new Date(new Date().setDate(new Date().getDate() - 21)).toDateString(),
            osa: [ worstOSA3weeks * 100, bestOSA3weeks * 100]
          })
          processedData.push({
            date: new Date(new Date().setDate(new Date().getDate() - 14)).toDateString(),
            osa: [ worstOSA2weeks * 100, bestOSA2weeks * 100]
          })
        }
      }
  
      if (data && type === 'traffic') {
        console.log('traffic',data)
        const bestData = data.best
        const worstData = data.worst
  
        if (bestData && worstData) {
            const bestDataByDate = groupDataByDate(bestData);
            const worstDataByDate = groupDataByDate(worstData);
  
            const dates = [...new Set([...Object.keys(bestDataByDate), ...Object.keys(worstDataByDate)])];
  
            dates.forEach((date) => {
              const bestDataForDate = bestDataByDate[date] || [];
              const worstDataForDate = worstDataByDate[date] || [];
  
              const bestDataAverage = calculateAverage(bestDataForDate);
              const worstDataAverage = calculateAverage(worstDataForDate);
  
              processedData.push({
                date,
                traffic: [bestDataAverage, worstDataAverage]
              })
            })
          }
  
          function groupDataByDate(data) {
            return data.reduce((result, item) => {
              const date = new Date(item.month_start_date).toDateString();
              if (!result[date]) {
                result[date] = [];
              }
              result[date].push(item);
              return result;
            }, {});
          }
  
          function calculateAverage(data) {
            const totalCounts = data.map((item) => parseFloat(item.total_count));
            const sum = totalCounts.reduce((total, count) => total + count, 0);
            return sum / totalCounts.length;
          }
      }
  
      if (data && type === 'sales') {
        console.log('sales',data)
        const bestData = data.best
        const worstData = data.worst
  
        if (bestData && worstData) {
          const bestDataByDate = groupDataByDate(bestData);
          const worstDataByDate = groupDataByDate(worstData);
  
          const dates = [...new Set([...Object.keys(bestDataByDate), ...Object.keys(worstDataByDate)])];
  
          dates.forEach((date) => {
            const bestDataForDate = bestDataByDate[date] || [];
            const worstDataForDate = worstDataByDate[date] || [];
  
            const bestDataAverage = calculateAverage(bestDataForDate);
            const worstDataAverage = calculateAverage(worstDataForDate);
  
            processedData.push({
              date,
              sales: [bestDataAverage, worstDataAverage]
            })
          })
          console.log('processedData for sales',processedData)
        }
  
        function groupDataByDate(data) {
          return data.reduce((result, item) => {
            const date = new Date(item.month_start_date).toDateString();
            if (!result[date]) {
              result[date] = [];
            }
            result[date].push(item);
            return result;
          }, {});
        }
  
        function calculateAverage(data) {
          const totalSales = data.map((item) => parseFloat(item.total_sales));
          const sum = totalSales.reduce((total, count) => total + count, 0);
          return sum / totalSales.length;
        }
      }
    if (!data) return (
      <div className="w-full flex justify-center h-40 items-center">
      <p className="w-1/2 rounded text-xs text-center border border-[#82ca9d] text-[#82ca9d] font-bold">NO DATA</p>
    </div> 
  )
    if (data.length < 1) return (
      <div className="w-full flex justify-center h-40 items-center">
      <p className="w-1/2 rounded text-xs text-center border border-[#82ca9d] text-[#82ca9d] font-bold">NO DATA</p>
    </div> 
  )

    return (
      <>
        {
          data && type === 'osa' ? (
            <>
              <ResponsiveContainer width="95%" height="80%" >
                <AreaChart data={processedData}
                  margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="colorPB" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#FF6C37" stopOpacity={0.7} />
                      <stop offset="95%" stopColor="#FF6C37" stopOpacity={0.2} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={(value) => value.toFixed(2)} domain={['dataMin - 5', 100]} />
                  <Tooltip />
                  <Area type="monotone" dataKey="osa" stroke="#1F2937" fillOpacity={1} fill="url(#colorPB)" />
                </AreaChart>
              </ResponsiveContainer>
            </>
          ) :
            data && type === 'traffic' ? (
              <>
                <ResponsiveContainer width="95%" height="80%">
                  <AreaChart data={processedData}
                    margin={{ top: 10, right: 10, left: 20, bottom: 0 }}>
                    <XAxis dataKey="date" />
                    <YAxis tickFormatter={(value) => value.toFixed(2)} domain={['dataMin - 5', 'dataMax + 5']} />
                    <Tooltip />
                    <Area type="monotone" dataKey="traffic" stroke="#1F2937" fillOpacity={1} fill="url(#colorPB)" />
                  </AreaChart>
                </ResponsiveContainer>
              </>
            ) :
              data && type === 'sales' ? (
                <>
                  <ResponsiveContainer width="95%" height="80%">
                    <AreaChart data={processedData}
                      margin={{ top: 10, right: 10, left: 10, bottom: 0 }}>
                      <defs>
                        <linearGradient id="colorPB" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.7} />
                          <stop offset="40%" stopColor="#97a883" stopOpacity={0.5} />
                          <stop offset="95%" stopColor="#ff0000" stopOpacity={0.1} />
                        </linearGradient>
                      </defs>
                      <XAxis dataKey="date" />
                      <YAxis tickFormatter={(value) => value.toFixed(2)} domain={['dataMin - 5', 'dataMax + 5']} />
                      <Tooltip />
                      <Area type="monotone" dataKey="sales" stroke="#1F2937" fillOpacity={1} fill="url(#colorPB)" />
                    </AreaChart>
                  </ResponsiveContainer>
                </>
              ) :
              (
                <p>No data</p>
              )
        }
      </>
      )
  } 