import { useState, useEffect, useContext } from 'react';
import SettingsArea from '../components/SettingsArea';
import Sidebar from '../components/Sidebar';
import FetchingData from '../components/FetchingData';
import TrafficData from '../components/TrafficData';
import { UserContext } from "../Context";

export default function Settings() {
  const [ idcloudData, setIdCloudData ] = useState()
  const [ defaultData, setDefaultData ] = useState()
  const [ userData, setUserData ] = useState()
  const [ cronData, setCronData ] = useState()

  const { user } = useContext(UserContext);
  // redirect the user to ("/") if user is undefined
  useEffect(() => {
    if (!user) {
      window.location.href = "/"
    }
  }, [user])

  useEffect(() => {
  const response = FetchingData(`/api/get-settings?organization_id=${user.organization_id}`, undefined, 'GET')
  response.then(res => {
    if (!res.error){
      if (res.OrgSettings[0].source === 'idcloud') {
        setIdCloudData(res.OrgSettings[0])
        setDefaultData(res.OrgSettings[1])
      } else {
        setIdCloudData(res.OrgSettings[1])
        setDefaultData(res.OrgSettings[0])
      }
      setUserData(res.UserSettings)
    } else {
      console.log(res.error)
    }
  })
  const response2 = FetchingData(`/api/organization-info?organizationId=${user.organization_id}`, undefined, 'GET')
  response2.then(res => {
    if (!res.error){
      setCronData(res)
    } else {
      console.log(res.error)
    }
  })

  }, [])

  return (

       (idcloudData && defaultData && userData) ? (
        <div className="flex flex-col bg-gray-700">
        <Sidebar/>
        <main className="flex-grow ml-48 relative h-full bg-gray-700 p-8">
                <h1 className='text-white text-2xl'>Settings</h1>
                <div className='p-2 mt-8'>
                  <section>
                    <SettingsArea title='Goods receiving' idcloudData={idcloudData} defaultData={defaultData} userData={userData} />
                  </section>
                </div>
                <div className='p-2 mt-8'>
                  <section>
                    <TrafficData />
                  </section>
                </div>
                <div className='p-2 mt-8'>
                  <section>
                    <h1 className='text-white text-xl'>Last iDCloud Updates</h1>
                    <div className='w-1/2'>
                      {
                        cronData && cronData.map((item, index) => {
                          return (
                            <div key={index} className='flex flex-col bg-gray-800 p-2 mt-2 w-full'>
                              <div className='flex justify-between'>
                                <p className='text-white font-bold text-base'>Job: </p>
                                <span className='text-white font-medium'>{item.cron_name}</span>
                              </div>
                              <div className='flex justify-between'>
                                <p className='text-white font-bold text-base'>Last Run: </p>
                                <span className='text-white font-medium'>{new Date(item.cron_time).toDateString()}</span>
                              </div>
                              <div className='flex justify-between'>
                                <p className='text-white font-bold text-base'>Last iDCloud data: </p>
                                <span className='text-white font-medium'>{new Date(item.last_entry).toDateString()}</span>
                              </div>
                              <div className='flex justify-between'>
                                <p className='text-white font-bold text-base'>Currently running?: </p>
                                <span className='text-white font-medium'>{item.active ? 'True' : 'False'}</span>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </section>
                </div>
        </main>
        </div>
      ) : (
        <div className="flex bg-gray-700 w-full h-screen place-content-center">
          <p className="text-white text-lg m-auto">Loading</p>
        </div>
      )
  )
}