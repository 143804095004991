import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

export default function PieChartStandard(props) {

  const { data } = props;
  let pieData = [];
  if (data) {
    let stores_active = 0;
    let stores_no_active = 0;
    let distribution_center = 0;
    let other = 0;
    data.forEach((item) => {
      if (item.type === "fashion" && item.active) {
        stores_active += 1;
      } else if (item.type === "fashion" && !item.active) {
        stores_no_active += 1;
      } else if (item.type === "distribution_center") {
        distribution_center += 1;
      } else {
        other += 1;
      }
    })
    pieData = [
      { name: "Stores active", value: stores_active },
      { name: "Stores no active", value: stores_no_active },
      { name: "Distribution center", value: distribution_center },
      { name: "Other", value: other },
    ];
  }

    const colors = [
        "#8884d8",
        "#82ca9d",
        "#BEBEBE",
        "#BEBEBE",
        "#BEBEBE",
        "#BEBEBE",
      ];
      return (
        <>
          <ResponsiveContainer width="100%" height="80%">
            <PieChart>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                fill="#8884d8"
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </>
      );
  }