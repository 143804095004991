export default function ListUsers(props) {
    const { listUsers } = props
    return (
        <>
            <div className="w-full grid grid-cols-2 gap-2 border border-gray-400 bg-gray-800 p-4">
                <p className="text-center text-white">Username</p>
                <p className="text-center text-white">Role</p>
                {
                    listUsers.map((user, index) => {
                        return (
                            <>
                                <p key={index} className="text-center text-white border-b border-gray-400">{user.username}</p>
                                <p key={index} className="text-center text-white border-b border-gray-400">{user.role}</p>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}