import Sidebar from '../components/Sidebar';
import AreaChartStandard from '../components/AreaChartStandard';
import LineChartStandard from '../components/LineChartStandard';
import AssumptionCard from '../components/AssumptionCard';
import CalculationBox from '../components/CalculationBox';
import FetchingData from '../components/FetchingData';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context';

export default function Receive() {
    const { user } = useContext(UserContext)
    const [userData, setUserData] = useState()
    const [receivingData, setReceivingData] = useState()

    useEffect(() => {
    const response = FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
    response.then(res => {
        if (!res.error){
            setUserData(res)
        } else {
            console.log(res.error)
        }
    })
    const response2 = FetchingData(`/api/receiving?organizationId=${user.organization_id}`, undefined, 'GET')
    response2.then(res => {
        if (!res.error){
            setReceivingData(res)
        } else {
            console.log(res.error)
        }
    })
    }, [])

  return (
    <>
    <div className="flex flex-col bg-gray-700 h-screen">
    <Sidebar/>
    <main className="flex-grow ml-48 relative h-full bg-gray-700">
        <section className='p-8'>
            <div className="w-full flex flex-col bg-gray-700">
                <h1 className="text-2xl text-white mb-8">Results</h1>
                <div className="flex w-full gap-2 mb-4">
                    <CalculationBox
                        title='Goods Receiving'
                        children={[
                            {
                                title: 'Number boxes received',
                                calculation: [],
                                result: userData?.boxes_received ?? 'N/A'
                            },
                            {
                                title: 'Seconds saved',
                                calculation: [userData?.boxes_received ?? 'N/A', 'x', parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0)],
                                result: parseFloat(userData?.boxes_received ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0)))
                            },
                            {
                                title: 'Hours saved',
                                calculation: [parseFloat(userData?.avg_items_received ?? 0) * (parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0)), '/', 3600],
                                result: Math.round(parseFloat(userData?.boxes_received ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0))) / 3600)
                            },
                            {
                                title: 'Money saved',
                                calculation: [Math.round(parseFloat(userData?.boxes_received ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0))) / 3600), 'x', parseFloat(userData?.price_per_working_hour ?? 0)],
                                result: Math.round((parseFloat(userData?.boxes_received ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0))) / 3600) * parseFloat(userData?.price_per_working_hour ?? 0))
                            }
                    ]} />
                    <div className="w-2/5 lg:w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                        <AreaChartStandard  data={receivingData} userData={userData} type={'receiving'} height={'100%'} />
                    </div>
                </div>
            </div>
            <h1 className="text-xl font-bold text-white mb-8">Assumptions</h1>
            <div className="flex flex-wrap gap-2">
                <AssumptionCard title='Boxes Received' children={{value: userData?.boxes_received ?? 'N/A', units: '/ boxes', description: 'Number of boxes received in all the active stores'}} />
                <AssumptionCard title='Average Items' children={{value: userData?.avg_items_received ?? 'N/A', units: '/ items', description: 'Average number of items in a box'}} />
                <AssumptionCard title='Box without RFID' children={{value: userData?.receive_box_no_rfid ?? 'N/A', units: '/ seconds', description: 'Average processing time of a box without RFID'}} />
                <AssumptionCard title='Box with RFID' children={{value: userData?.receive_box_rfid ?? 'N/A', units: '/ seconds', description: 'Average processing time of a box with RFID'}} />
            </div>
        </section>
        {
            /*
        <section className='w-full bg-gray-700 p-8'>
            <h1 className="text-xl text-white mb-8">iD Cloud Data</h1>
            <div className='flex gap-2'>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-xl text-white mb-8">Time saved</h1>
                    <LineChartStandard/>
                </div>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-xl text-white mb-8">Money saved</h1>
                    <LineChartStandard/>
                </div>
            </div>
        </section>
            */
        }
    </main>
    </div>
    </>
  )
}