//get context user data
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../Context"
import FetchingData from "./FetchingData"

export default function SettingsArea (props) {
    const user = useContext(UserContext)
    const [organizationName, setOrganizationName] = useState('')
    const [inputValue, setInputValue] = useState('')
    const [response, setResponse] = useState('')
    let {idcloudData, defaultData, userData} = props

    useEffect(() => {
        if (user) {
            setOrganizationName(user.user.scope.filter((org) => org.organization_id === user.user.organization_id)[0].name)
        }
    }, [])

    useEffect(() => {
        if (response) {
            document.getElementById(`${response}-button`).innerHTML = '✔'
            setTimeout(() => {
                document.getElementById(`${response}-button`).innerHTML = 'Save'
            }, 1000)
        }
    }, [response])

    useEffect(() => {
        if (inputValue) {
            if (document.getElementById(`${inputValue.name}-default`).value === defaultData.filter((data) => data[0] === inputValue.name)[1] && document.getElementById(`${inputValue.name}-user`).value === userData.filter((data) => data[0] === inputValue.name)[1]) {
                document.getElementById(`${inputValue.name}-button`).setAttribute('disabled', 'disabled')
            } else {
                document.getElementById(`${inputValue.name}-button`).removeAttribute('disabled')
            }
        }
    }, [inputValue])
    
    if (!idcloudData) idcloudData = []
    if (!defaultData) return []
    if (!userData) return []
    
    idcloudData = Object.entries(idcloudData)
    defaultData = Object.entries(defaultData)
    userData = Object.entries(userData[0])
    
    const onChangeInput = (name, input) => {
        setInputValue({name, input})
    }

    const updateData = async(name) => {
        const response = FetchingData(`/api/update-settings`, {organizationId: user.user.organization_id, setting: name, defaultSet: document.getElementById(`${name}-default`).value, userSet: document.getElementById(`${name}-user`).value}, 'POST')
        response.then(res => {
            if (!res.error){
                setResponse(name)
            } else {
                console.log(res?.error)
            }
        })
    }

    // map all the child[0] to show the correct human readable name
    const settings = {
        active_stores : 'Active Stores',
        average_basked_size : 'Average Basket Size',
        average_revenue_per_store : 'Average Revenue Per Store',
        avg_items_received : 'Average Items Received',
        boxes_received : 'Boxes Received', 
        checkout_time_saving : 'Checkout Time Saving',
        critical_sales_uplift : 'Critical Sales Uplift',
        eas_active_stores : 'EAS Active Stores',
        hardtag_percentage : 'Hardtag Percentage',
        hours_per_physical_counts : 'Hours per Physical Counts',
        items_sold_per_year : 'Items Sold per Year',
        organization_id : 'Organization ID',
        osa_sales_uplift : 'OSA Sales Uplift',
        other_eas_price : 'Other EAS Price',
        other_eas_tagging : 'Other EAS Tagging',
        physical_counts : 'Physical Counts',
        price_per_working_hour : 'Price per Working Hour',
        receive_box_no_rfid : 'Receive Box without RFID',
        receive_box_rfid : 'Receive Box with RFID',
        spent_in_close_stores : 'Spent in Close Stores',
        spent_in_scanners : 'Spent in Scanners',
        spent_in_software : 'Spent in Software',
        time_apply_hardtag : 'Time to Apply Hardtag',
        time_remove_hardtag : 'Time to Remove Hardtag'
    }

    return (
        <>
            <h1 className="text-xl text-white">{`${organizationName}`}</h1>
            <h1 className="text-s text-white mb-8">{`Organization Id: ${user?.user?.organization_id}`}</h1>
            <div className="mt-4 rounded-xl bg-gray-800 p-2">
                <div className="w-full grid grid-cols-5 gap-2 p-2 text-white font-bold border-b border-gray-500">
                    <p className="px-2 mx-4 text-center">DESCRIPTION</p>
                    <p className="px-2 mx-4 text-center">iD CLOUD</p>
                    <p className="px-2 mx-4 text-center">DEFAULT</p>
                    <p className="px-2 mx-4 text-center">USER</p>
                    <p className="px-2 mx-4 text-center">ACTION</p>
                </div>
                {
                    defaultData.map((child, index)=> {
                        if (child[0] === 'source') return null
                        // console.log(userData[index])
                        return (
                            <div className="w-full grid grid-cols-5 gap-8 p-2 pl-4 text-white border-b border-gray-700 items-center" key={index}>
                                <p className="border-r border-gray-600">{settings[child[0]]}</p>
                                <p className="text-center text-gray-300">{idcloudData[index]['1'] ?? '-'}</p>
                                {
                                    user.user === 'superuser' ? <>
                                        <input className="bg-gray-800 rounded-lg px-2 text-center" id={`${child[0]}-default`} defaultValue={defaultData[index]['1']} disabled/>
                                    </> :
                                    <>
                                        {
                                            idcloudData[index] === defaultData[index]['1'] ? 
                                            <>
                                                <input className="bg-gray-700 rounded-lg px-2 text-center" id={`${child[0]}-default`} defaultValue={defaultData[index]['1']} />
                                            </>
                                            :
                                            <>
                                                <input className="bg-gray-700 rounded-lg px-2 text-center border border-green-600" id={`${child[0]}-default`} defaultValue={defaultData[index]['1']} />
                                            </>
                                        }
                                    </>
                                }
                                {
                                    userData[index]['1'] === defaultData[index]['1'] ?
                                    <>
                                        <input className="bg-gray-700 rounded-lg px-2 text-center" id={`${child[0]}-user`} defaultValue={userData[index]['1']} onChange={() => onChangeInput(child[0], 'default')}/>                                    
                                    </>
                                    :
                                    <>
                                        <input className="bg-gray-700 rounded-lg px-2 text-center border border-blue-600" id={`${child[0]}-user`} defaultValue={userData[index]['1']} onChange={() => onChangeInput(child[0], 'user')}/>                                    
                                    </>
                                }
                                <button className="bg-gray-700 rounded-lg px-2 text-gray-200" id={`${child[0]}-button`} onClick={() => updateData(child[0])}>Save</button>
                            </div>
                        )})
                }
            </div>
        </>
    )
}