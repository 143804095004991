export default function AssumptionCard({title, children}) {
    return (
    <div className='relative border border-slate-500 rounded p-4 h-80 flex flex-col justify-between min-w-60 max-w-60 bg-gray-800'>
        <h1 className="text-xl font-bold text-white text-center pt-4">{title}</h1>
        <div className='absolute inset-x-4 bottom-40 flex justify-center items-center'>
            <p className="text-2xl text-gray-200 text-center font-mono border border-slate-500 bg-gray-500 rounded px-2" >{parseFloat(children.value).toLocaleString()}</p>
            <p className='text-lg text-gray-200'>{children.units}</p>
        </div>
        <p className="text-lg text-white text-center">{children.description}</p>
    </div>
    )
}

