export default async function FetchingData(url, body, method) {
    //const BASE_URL = 'http://localhost:3000'
    const BASE_URL = 'https://value.dashboard-nedap-retail.com'
    const fetchData = async () => {
        try {
            let res = {}
            const response = await fetch(BASE_URL + url, {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            if (response.status === 200) {
                res = await response.json()
                return res
            } else {
                const errorRes = await response.text()
                console.error('Error:', errorRes)
                res.error = errorRes
                res.status = response.status
                return res
            }
        } catch (error) {
            console.log('this is an error', error)
        }
    }

    return fetchData()
}