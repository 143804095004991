import React, { useState, useEffect } from 'react';

const CustomSelect = ({ options, onChange, current }) => {
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    if (current) {
      setSelectedValue(current);
    }
  }, [current]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <select className='text-xs text-gray-400 bg-gray-800' value={selectedValue} onChange={handleSelectChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;