import Sidebar from '../components/Sidebar';
import AreaChartStandard from '../components/AreaChartStandard';
import LineChartStandard from '../components/LineChartStandard';
import AssumptionCard from '../components/AssumptionCard';
import CalculationBox from '../components/CalculationBox';
import FetchingData from '../components/FetchingData';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context';

export default function Hardtags() {
    const { user } = useContext(UserContext)
    const [userData, setUserData] = useState()

    useEffect(() => {
        const syncData = async () => {
            const response = await FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
            if (!response.error){
                setUserData(response)
            } else {
                console.error(response.error)
            }
        }
        syncData()
    

    }, [])

  return (
    <>
    <div className="flex flex-col bg-gray-700">
    <Sidebar/>
    <main className="flex-grow ml-48 relative h-screen">
        <section className='p-8 bg-gray-700'>
            <div className="w-full flex flex-col bg-gray-700">
                <h1 className="text-2xl text-white mb-8">Results</h1>
                <div className="flex w-full gap-2 mb-4">
                    <CalculationBox
                        title='Hardtags'
                        children={[
                            {
                                title: 'Hardtags per year',
                                calculation: [userData?.items_sold_per_year ?? 'N/A', 'x', parseFloat(userData?.hardtag_percentage ?? 0) / 100],
                                result: parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)
                            },
                            {
                                title: 'Application hours',
                                calculation: [userData?.items_sold_per_year * (parseFloat(userData?.hardtag_percentage ?? 0) / 100), 'x', userData?.time_apply_hardtag ?? 'N/A', '/', 3600],
                                result: ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_apply_hardtag ?? 0) / 3600).toFixed(0)
                            },
                            {
                                title: 'Detaching hours',
                                calculation: [parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100), 'x', userData?.time_remove_hardtag ?? 'N/A', '/', 3600],
                                result: ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_remove_hardtag ?? 0) / 3600).toFixed(0)
                            },
                            {
                                title: 'AM cost',
                                calculation: [userData?.items_sold_per_year ?? 'N/A', 'x', ((userData?.other_eas_tagging ?? 0) / 100) , 'x', userData?.other_eas_price ?? 'N/A'],
                                result: parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.other_eas_tagging ?? 0) / 100) * parseFloat(userData?.other_eas_price ?? 0)
                            },
                            {
                                title: 'Total price',
                                calculation: [ '(', ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_apply_hardtag ?? 0) / 3600).toFixed(0), '+', ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_remove_hardtag ?? 0) / 3600).toFixed(0), ')', 'x', userData?.price_per_working_hour ?? 'N/A', '+', parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.other_eas_tagging ?? 0) / 100) * parseFloat(userData?.other_eas_price ?? 0)],
                                result: (((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_apply_hardtag ?? 0) / 3600) + ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_remove_hardtag ?? 0) / 3600)) * parseFloat(userData?.price_per_working_hour ?? 0) + (parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.other_eas_tagging ?? 0) / 100) * parseFloat(userData?.other_eas_price ?? 0))
                            
                            }
                    ]} />
                    <div className="w-2/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                        <AreaChartStandard height={'100%'} />
                    </div>
                </div>
            </div>
            <h1 className="text-lg font-bold text-white mb-8">Assumptions</h1>
            <div className="flex flex-wrap gap-2">
                <AssumptionCard title={'Stores'} children={{value: userData?.eas_active_stores ?? 'N/A', units: '/ stores', description: 'Number of stores'}} />
                <AssumptionCard title={'Items Sold'} children={{value: userData?.items_sold_per_year ?? 'N/A', units: '/ items', description: 'Number of items sold per year'}} />
                <AssumptionCard title={'Tagging percentage'} children={{value: userData?.hardtag_percentage ?? 'N/A', units: '%', description: 'Percentage of items hard-tagged per year'}} />
                <AssumptionCard title={'Apply Hardtag'} children={{value: userData?.time_apply_hardtag ?? 'N/A', units: '/ seconds', description: 'Time to apply a hard tag'}} />
                <AssumptionCard title={'Remove Hardtag'} children={{value: userData?.time_remove_hardtag ?? 'N/A', units: '/ seconds', description: 'Time to detach and remove a hard tag'}} />
                <AssumptionCard title={'AM tagging'} children={{value: userData?.other_eas_tagging ?? 'N/A', units: '%', description: 'AM hard tags applied'}} />
                <AssumptionCard title={'AM tagging price'} children={{value: userData?.other_eas_price ?? 'N/A', units: '/ €', description: 'AM soft tag cost'}} />
                <AssumptionCard title={'Manual labor cost'} children={{value: userData?.price_per_working_hour ?? 'N/A', units: '/ €', description:'Average Manual labor price'}} />
            </div>
        </section>
    </main>
    </div>
    </>
  )
}