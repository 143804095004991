import { useEffect, useState } from 'react';
import AreaChartStandard from './AreaChartStandard';
import PieChartStandard from './PieChartStandard';
import { Link } from "react-router-dom";
import FetchingData from './FetchingData';

export default function MainDashboard(props) {
    const { userData } = props
    const [receivingData, setReceivingData] = useState()
    const [countData, setCountData] = useState()
    const [locationData, setLocationData] = useState()
    const [checkoutData, setCheckoutData] = useState()
    
    useEffect(() => {
        const getData = async () => {
            const resReceive = FetchingData(`/api/receiving?organizationId=${userData.organization_id}`, undefined, 'GET')
            resReceive.then(res => {
                if (!res.error){
                    setReceivingData(res)
                } else {
                    console.log(res.error)
                }
            })
            const resCount = FetchingData(`/api/count?organizationId=${userData.organization_id}`, undefined, 'GET')
            resCount.then(res => {
                if (!res.error){
                    setCountData(res)
                } else {
                    console.log(res.error)
                }
            })
            const resLocation = FetchingData(`/api/location?organizationId=${userData.organization_id}`, undefined, 'GET')
            resLocation.then(res => {
                if (!res.error){
                    setLocationData(res)
                } else {
                    console.log(res.error)
                }
            })
        }
        if (userData) {
            getData()
        }
    }, [userData])

  return (
      <section className="w-full h-screen flex flex-col items-center justify-center pr-8">
        
        <div className="flex w-full h-80 m-2 p-2 gap-2 bg-gray-700">
            <div className="flex flex-col w-1/5 justify-center">
                <h1 className="text-xl text-white text-center">Stores</h1>
                <PieChartStandard data={locationData} />
            </div>
            <Link to="/receive" className="flex flex-col w-1/5 px-2 pt-2 justify-evenly bg-gray-800 shadow rounded border border-slate-500 ">
                <div>                    
                    <h1 className="text-xl text-white text-center">Receiving</h1>
                    <h1 className='text-xs text-white text-center'>In the last year</h1>
                </div>
                <div>
                    <p className='text-gray-400 text-xs pl-8'>saved hours:</p>
                    <p className="text-xl text-gray-200 text-center font-mono">{Math.round(parseFloat(userData?.boxes_received ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0))) / 3600).toLocaleString()} <span className='text-sm'>/ hr</span></p>
                    <p className='text-gray-400 text-xs pl-8'>estimated cost</p>
                    <p className="text-sm text-gray-400 text-center font-mono">{Math.round((parseFloat(userData?.boxes_received ?? 0) * ((parseFloat(userData?.receive_box_no_rfid ?? 0) - parseFloat(userData?.receive_box_rfid ?? 0))) / 3600) * parseFloat(userData?.price_per_working_hour ?? 0)).toLocaleString()} €</p>
                </div>
                <AreaChartStandard data={receivingData} userData={userData} type={'receiving'} height={'40%'} />
            </Link>
            <Link to="/count" className="flex flex-col w-1/5 px-2 pt-2 justify-evenly bg-gray-800 shadow rounded border border-slate-500 ">
                <div>
                    <h1 className="text-xl text-white text-center">Year end count</h1>
                    <h1 className='text-xs text-white text-center'>In the last year</h1>
                </div>
                <div>
                    <p className='text-gray-400 text-xs pl-8'>saved hours:</p>
                    <p className="text-xl text-gray-200 text-center font-mono">{(parseFloat(userData?.physical_counts ?? 0) * parseFloat(userData?.hours_per_physical_counts ?? 0) * parseFloat(userData?.active_stores ?? 0)).toLocaleString()} <span className='text-sm'>/ hr</span></p>
                    <p className='text-gray-400 text-xs pl-8'>estimated cost</p>
                    <p className="text-sm text-gray-400 text-center font-mono">{((parseFloat(userData?.physical_counts ?? 0) * parseFloat(userData?.hours_per_physical_counts ?? 0) * parseFloat(userData?.active_stores ?? 0)) * userData?.price_per_working_hour ?? 'N/A').toLocaleString()} €</p>
                </div>
                <AreaChartStandard data={countData} userData={userData} type={'count'} height={'40%'}/>
            </Link>
            <Link to="/onshelf" className="flex flex-col w-1/5 px-2 pt-2 justify-evenly bg-gray-800 shadow rounded border border-slate-500 ">
                <div>
                    <h1 className="text-xl text-white text-center">On Shelf Availability</h1>
                    <h1 className='text-xs text-white text-center'>per year</h1>
                </div>
                <div>
                    <p className='text-gray-400 text-xs pl-8'>estimated sales uplift:</p>
                    <p className="text-lg text-gray-200 text-center font-mono">{((parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0)) * (parseFloat(userData?.osa_sales_uplift ?? 0) / 100)).toLocaleString()} €</p>
                </div>
                <AreaChartStandard data={countData} userData={userData} type={'onshelf'} height={'40%'} />
            </Link>
            <Link to="/critical" className="flex flex-col w-1/5 px-2 pt-2 justify-evenly bg-gray-800 shadow rounded border border-slate-500 ">
                <div>
                    <h1 className="text-xl text-white text-center">Critical Zero</h1>
                    <h1 className='text-xs text-white text-center'>per year</h1>
                </div>
                <div>
                    <p className='text-gray-400 text-xs pl-8'>estimated sales uplift:</p>
                    <p className="text-lg text-gray-200 text-center font-mono">{(parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0) * (parseFloat(userData?.critical_sales_uplift ?? 0) / 100 )).toLocaleString()} €</p>
                </div>
                <AreaChartStandard data={countData} userData={userData} type={'critical'} height={'40%'} />
            </Link>
        </div>
        <div className="flex w-full h-45 m-2 p-2 gap-2 bg-gray-700">
            <div className="flex flex-col w-1/5 justify-center">
            </div>
            <div className="flex flex-col w-4/5 gap-4 justify-center bg-gray-500 shadow rounded border border-slate-500 items-center">
                <h1 className="text-xl text-black font-semibold text-center">Untapped</h1>
                <div className='flex gap-2 w-1/2 mb-2'>
                    <Link to="/hardtags" className='flex flex-col justify-evenly w-1/2 bg-gray-700 border border-gray-400 h-32'>
                        <h1 className="text-xl text-white text-center">Reduce Hard Tags</h1>
                        <div>
                            <p className='text-gray-400 text-xs pl-8'>saved hours:</p>
                            <p className="text-xl text-gray-200 text-center font-mono">{(((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_apply_hardtag ?? 0) / 3600) + ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_remove_hardtag ?? 0) / 3600)).toLocaleString()} <span className='text-sm'>/ hr</span></p>
                            <p className='text-gray-400 text-xs pl-8'>estimated cost</p>
                            <p className="text-sm text-gray-400 text-center font-mono">{((((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_apply_hardtag ?? 0) / 3600) + ((parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.hardtag_percentage ?? 0) / 100)) * parseFloat(userData?.time_remove_hardtag ?? 0) / 3600)) * parseFloat(userData?.price_per_working_hour ?? 0) + (parseFloat(userData?.items_sold_per_year ?? 0) * (parseFloat(userData?.other_eas_tagging ?? 0) / 100) * parseFloat(userData?.other_eas_price ?? 0))).toLocaleString()} €</p>
                        </div>
                    </Link>
                    <Link to="/checkout" className='flex flex-col justify-evenly w-1/2 bg-gray-700 border border-gray-400 h-32'>
                    <h1 className="text-xl text-white text-center">Quicker Checkout</h1>
                    <div>
                            <p className='text-gray-400 text-xs pl-8'>saved hours:</p>
                            <p className="text-xl text-gray-200 text-center font-mono">{Math.round((parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0) * parseFloat(userData?.checkout_time_saving ?? 0)) / 3600).toLocaleString()} <span className='text-sm'>/ hr</span></p>
                            <p className='text-gray-400 text-xs pl-8'>estimated cost</p>
                            <p className="text-sm text-gray-400 text-center font-mono">{Math.round((parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0) * parseFloat(userData?.checkout_time_saving ?? 0)) / 3600 * parseFloat(userData?.price_per_working_hour ?? 0)).toLocaleString()} €</p>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
      </section>
  );
};