import React, { useState } from "react";


const UserContext = React.createContext()

const UserProvider = ({ children }) => {
    const [ user, setUser ] = useState()
    
    const toggleUser = (orgId, user, scope) => {
        if (orgId === undefined || user === undefined || scope === undefined) {
            setUser()
        } else {
            setUser({ organization_id: orgId, user, scope })
        }
    }

    return (
        <UserContext.Provider value={{ user, toggleUser}}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider}