export default function CalculationBox({title, children, width}) {
    let widthSize = 'w-3/5'
    if (width) {
        widthSize = width
    }
    return (
        <div className={`flex flex-col ${widthSize} justify-between bg-gray-800 h-80 shadow rounded border border-slate-500`}>
        <h1 className="text-lg text-white text-center my-2 mb-16">{title}</h1>
        <div className='h-full'>
            {
                children.map((child, index) => {
                    return (
                        <>
                            <div key={index} className='flex flex-nowrap justify-between items-baseline gap-4 px-4'>
                                <p className="text-base text-gray-200 text-center" >{child.title}</p>
                                <p className='text-base text-gray-200'>
                                    {
                                        Array.isArray(child.calculation) ? (
                                            child.calculation.map((calc, index) => {
                                                if (calc === 'x' || calc === '/' || calc === '+' || calc === '-' || calc === '(' || calc === ')' || calc === '=')
                                                    return (
                                                        <span key={index} className='text-gray-200 px-2'>{calc}</span>
                                                    )
                                                else
                                                    return (
                                                        <span key={index} className='font-mono border border-slate-500 bg-gray-500 rounded px-2'>{parseFloat(calc).toLocaleString()}</span>
                                                    )
                                            })
                                        ) : (
                                            <span>{child.calculation}</span>
                                        )
                                    }
                                </p>
                                <p className='text-gray-200 font-mono border border-slate-500 bg-gray-500 rounded px-2'>{parseFloat(child.result).toLocaleString()}</p>
                            </div>
                            <hr className='border border-slate-500 mx-6 my-2'/>
                        </>
                    )
                })
            }                            
        </div>
    </div>
    )
}