import AreaChartStandard from '../components/AreaChartStandard';
import Sidebar from '../components/Sidebar';
import LineChartStandard from '../components/LineChartStandard';
import CalculationBox from '../components/CalculationBox';
import AssumptionCard from '../components/AssumptionCard';
import FetchingData from '../components/FetchingData';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context';

export default function Critical() {
    const { user } = useContext(UserContext)
    const [userData, setUserData] = useState()
    const [countData, setCountData] = useState()

    useEffect(() => {
        const response = FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
        response.then(res => {
            if (!res.error){
                setUserData(res)
            } else {
                console.log(res.error)
            }
        })
        const response2 = FetchingData(`/api/count?organizationId=${user.organization_id}`, undefined, 'GET')
        response2.then(res => {
            if (!res.error){
                setCountData(res)
            } else {
                console.log(res.error)
            }
        })

    }, [])

  return (
    <>
    <div className="flex flex-col bg-gray-700">
    <Sidebar/>
    <main className="flex-grow ml-48 relative h-screen">
        <section className='p-8'>
            <div className="w-full flex flex-col bg-gray-700">
                <h1 className="text-2xl text-white mb-8">Results</h1>
                <div className="flex w-full gap-2 mb-4">
                    <CalculationBox
                        title='Critical Zero'
                        children={[
                            {
                                title: 'Revenue last year (equally divided across stores)',
                                calculation: [],
                                result: userData?.average_revenue_per_store ?? 'N/A'
                            },
                            {
                                title: 'Total Revenue',
                                calculation: [userData?.active_stores ?? 'N/A', 'x', userData?.average_revenue_per_store ?? 'N/A'],
                                result: parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0)
                            },
                            {
                                title: 'Sales Uplift',
                                calculation: [parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0), 'x', userData?.critical_sales_uplift ?? 'N/A'],
                                result: parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0) * (parseFloat(userData?.critical_sales_uplift ?? 0) / 100)
                            }
                    ]} />
                    <div className="w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                        <AreaChartStandard data={countData} userData={userData} type={'critical'} height={'100%'} />
                    </div>
                </div>
            </div>
            <h1 className="text-xl font-bold text-white mb-8">Assumptions</h1>
            <div className="flex flex-wrap gap-2">
                <AssumptionCard title='Sales Uplift' children={{value: userData?.critical_sales_uplift ?? 'N/A', units: '%', description: 'Sales uplift as a result of better counts'}} />
                <AssumptionCard title='Active Stores' children={{value: userData?.active_stores ?? 'N/A', units: '/ stores', description: 'Number of active stores'}} />
                <AssumptionCard title='Average Revenue' children={{value: userData?.average_revenue_per_store ?? 'N/A', units: ' / €', description: 'Revenue for 501 stores in 2023 (equally divided across stores)'}} />
            </div>
        </section>
    </main>
    </div>
    </>
  )
}