import Sidebar from '../components/Sidebar';
import LineChartStandard from '../components/LineChartStandard';
import AreaChartStandard from '../components/AreaChartStandard';
import AssumptionCard from '../components/AssumptionCard';
import CalculationBox from '../components/CalculationBox';
import FetchingData from '../components/FetchingData';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context';
import AreaChartOsa from '../components/AreaChartOSA';

export default function Onshelf() {
    const { user } = useContext(UserContext)
    const [userData, setUserData] = useState()
    const [bestData, setBestData] = useState() // bestData
    const [worstData, setWorstData] = useState()
    const [bestWorstLocations, setBestWorstLocations] = useState()
    const [osaData, setOsaData] = useState()
    const [customerAvgCountingData, setCustomerAvgCountingData] = useState()
    const [salesAvgData, setSalesAvgData] = useState([])
    const [bestSalesData, setBestSalesData] = useState() // bestSalesData
    const [worstSalesData, setWorstSalesData] = useState()
    const [bestCustomerCountingData, setBestCustomerCountingData] = useState() // bestCustomerCountingData
    const [worstCustomerCountingData, setWorstCustomerCountingData] = useState()
    const [conversionRate, setConversionRate] = useState()
    const [bestworstConversionRate, setBestWorstConversionRate] = useState()
    const [bestWorstOSAAcc, setBestWorstOSAAcc] = useState()
    const [bestWorstTrafficAcc, setBestWorstTrafficAcc] = useState()
    const [bestWorstSalesAcc, setBestWorstSalesAcc] = useState()

    useEffect(() => {
        const syncData = async () => {
       const response = await FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
       if (response?.error) {
        console.log(response?.error)
        setUserData()
         } else {
            setUserData(response)
        }
        
        const response2 = await FetchingData(`/api/onshelf?organizationId=${user.organization_id}&type=average`, undefined, 'GET')
        if (response2?.error) {
            console.log(response2?.error)
            setOsaData()
        } else {
            setOsaData(response2)
        }

        const response3 = await FetchingData(`/api/onshelf?organizationId=${user.organization_id}&type=bestworst&numStores=10`, undefined, 'GET')
        if (response3?.error) {
            console.log(response3.error)
            setBestData()
            setWorstData()
        } else {
            setBestWorstLocations({best: response3?.best?.map((item) => item.store), worst: response3?.worst?.map((item) => item.store)})
            setBestData(response3?.best)
            setWorstData(response3?.worst)
        }
        const response4 = await FetchingData(`/api/customer-counting?organizationId=${user.organization_id}&type=average`, undefined, 'GET')
        if (response4?.error) {
            console.log(response4.error)
            setCustomerAvgCountingData()
        } else {
            console.log('response4', response4)
            setCustomerAvgCountingData(response4)
            console.log('customer counting data retrieved')
        }
        const response5 = await FetchingData(`/api/sales?organizationId=${user.organization_id}&type=average`, undefined, 'GET')
        if (response5?.error) {
            console.log(response5.error)
            setSalesAvgData()
        } else {
            setSalesAvgData(response5)
            console.log('sales data retrieved')
        }
    }
    if (user) {
        syncData()
        console.log('useEffect []')
    }
    }, [])

    useEffect(() => {
        const syncData = async () => {
            let bestUrl = ''
            let worstUrl = ''   
            bestWorstLocations?.best?.forEach(async location => {
                bestUrl += `&store[]=${encodeURIComponent(location)}`
            })
            bestWorstLocations?.worst?.forEach(async location => {
                worstUrl += `&store[]=${encodeURIComponent(location)}`
            })
            const responseBest = await FetchingData(`/api/sales?type=bestworst&organizationId=${user.organization_id}${bestUrl}`, undefined, 'GET')
            if (responseBest?.error) {
                console.log(responseBest?.error)
                setBestSalesData()
            } else {
                setBestSalesData(responseBest)
            }
            const responseWorst = await FetchingData(`/api/sales?type=bestworst&organizationId=${user.organization_id}${worstUrl}`, undefined, 'GET')
            if (responseWorst?.error) {
                console.log(responseWorst?.error)
                setWorstSalesData()
            } else {
                setWorstSalesData(responseWorst)
            }
            const response2Best = await FetchingData(`/api/customer-counting?type=bestworst&organizationId=${user.organization_id}${bestUrl}`, undefined, 'GET')
            if (response2Best?.error){
                console.log(response2Best.error)
                setBestCustomerCountingData()
            } else {
                setBestCustomerCountingData(response2Best)
            }
            const response2Worst = await FetchingData(`/api/customer-counting?type=bestworst&organizationId=${user.organization_id}${worstUrl}`, undefined, 'GET')
            if (response2Worst?.error){
                console.log(response2Worst.error)
                setWorstCustomerCountingData()
            } else {
                setWorstCustomerCountingData(response2Worst)
            }
        }
        if (user && bestWorstLocations) {
            syncData()
        }
    }, [bestWorstLocations, user])

    useEffect(() => {
        if (bestData && worstData) {
        const worstOSAyear = worstData.reduce((total, next) => total + parseFloat(next.average_osa_year ) , 0) / worstData.length
        const bestOSAyear = bestData.reduce((total, next) => total + parseFloat(next.average_osa_year ), 0) / bestData.length
        const worstOSA5weeks = worstData.reduce((total, next) => total + parseFloat(next.average_osa_5_weeks ?? worstOSAyear), 0) / worstData.length
        const bestOSA5weeks = bestData.reduce((total, next) => total + parseFloat(next.average_osa_5_weeks ?? bestOSAyear), 0) / bestData.length
        const worstOSA3weeks = worstData.reduce((total, next) => total + parseFloat(next.average_osa_3_weeks ?? worstOSA5weeks), 0) / worstData.length
        const bestOSA3weeks = bestData.reduce((total, next) => total + parseFloat(next.average_osa_3_weeks ?? bestOSA5weeks), 0) / bestData.length
        const worstOSA2weeks = worstData.reduce((total, next) => total + parseFloat(next.average_osa_2_week ?? worstOSA3weeks), 0) / worstData.length
        const bestOSA2weeks = bestData.reduce((total, next) => total + parseFloat(next.average_osa_2_week ?? bestOSA3weeks), 0) / bestData.length

        setBestWorstOSAAcc({
          average_year: (bestOSAyear + worstOSAyear) / 2,
          average_5_weeks: (bestOSA5weeks + worstOSA5weeks) / 2,
          average_3_weeks: (bestOSA3weeks + worstOSA3weeks) / 2,
          average_2_weeks: (bestOSA2weeks + worstOSA2weeks) / 2
        })
        }
    }, [bestData, worstData])

    useEffect(() => {
        if (bestCustomerCountingData, worstCustomerCountingData) {
            let processedData = []
            const bestDataByDate = groupDataByDate(bestCustomerCountingData);
            const worstDataByDate = groupDataByDate(worstCustomerCountingData);
  
            const dates = [...new Set([...Object.keys(bestDataByDate), ...Object.keys(worstDataByDate)])];
  
            dates.forEach((date) => {
              const bestDataForDate = bestDataByDate[date] || [];
              const worstDataForDate = worstDataByDate[date] || [];
  
              const bestDataAverage = calculateAverage(bestDataForDate);
              const worstDataAverage = calculateAverage(worstDataForDate);
  
              processedData.push({
                date,
                traffic: [bestDataAverage, worstDataAverage]
              })
            })
            // from processedData, get the worst and the best average for the last year, 5 weeks, 3 weeks, 2 weeks
            const lastYearData = processedData.find((item) => item.date >= new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toDateString());
            const last5WeeksData = processedData.find((item) => item.date >= new Date(new Date().setDate(new Date().getDate() - 35)).toDateString());
            const last3WeeksData = processedData.find((item) => item.date >= new Date(new Date().setDate(new Date().getDate() - 21)).toDateString());
            const last2WeeksData = processedData.find((item) => item.date >= new Date(new Date().setDate(new Date().getDate() - 14)).toDateString());
    
            const worstAverageYear = Math.min(lastYearData.traffic[0], lastYearData.traffic[1]);
            const bestAverageYear = Math.max(lastYearData.traffic[0], lastYearData.traffic[1]);
    
            const worstAverage5Weeks = Math.min(last5WeeksData.traffic[0], last5WeeksData.traffic[1]);
            const bestAverage5Weeks = Math.max(last5WeeksData.traffic[0], last5WeeksData.traffic[1]);
    
            const worstAverage3Weeks = Math.min(last3WeeksData.traffic[0], last3WeeksData.traffic[1]);
            const bestAverage3Weeks = Math.max(last3WeeksData.traffic[0], last3WeeksData.traffic[1]);
    
            const worstAverage2Weeks = Math.min(last2WeeksData.traffic[0], last2WeeksData.traffic[1]);
            const bestAverage2Weeks = Math.max(last2WeeksData.traffic[0], last2WeeksData.traffic[1]);
    
            setBestWorstTrafficAcc({
              average_year: (bestAverageYear + worstAverageYear) / 2,
              average_5_weeks: (bestAverage5Weeks + worstAverage5Weeks) / 2,
              average_3_weeks: (bestAverage3Weeks + worstAverage3Weeks) / 2,
              average_2_weeks: (bestAverage2Weeks + worstAverage2Weeks) / 2
            })
        }
        function groupDataByDate(data) {
            return data.reduce((result, item) => {
              const date = new Date(item.month_start_date).toDateString();
              if (!result[date]) {
                result[date] = [];
              }
              result[date].push(item);
              return result;
            }, {});
          }
  
          function calculateAverage(data) {
            const totalCounts = data.map((item) => parseFloat(item.total_count));
            const sum = totalCounts.reduce((total, count) => total + count, 0);
            return sum / totalCounts.length;
          }

    }, [bestCustomerCountingData, worstCustomerCountingData])

    useEffect(() => {
        if (bestSalesData && worstSalesData) {
            let processedData = []
            const bestDataByDate = groupDataByDate(bestSalesData);
            const worstDataByDate = groupDataByDate(worstSalesData);
    
            const dates = [...new Set([...Object.keys(bestDataByDate), ...Object.keys(worstDataByDate)])];
    
            dates.forEach((date) => {
              const bestDataForDate = bestDataByDate[date] || [];
              const worstDataForDate = worstDataByDate[date] || [];
    
              const bestDataAverage = calculateAverage(bestDataForDate);
              const worstDataAverage = calculateAverage(worstDataForDate);
    
              processedData.push({
                date,
                sales: [bestDataAverage, worstDataAverage]
              })
            })
            // from processedData, get the worst and the best average for the last year, 5 weeks, 3 weeks, 2 weeks
            const lastYearData = processedData.find((item) => item.date >= new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toDateString());
            const last5WeeksData = processedData.find((item) => item.date >= new Date(new Date().setDate(new Date().getDate() - 35)).toDateString());
            const last3WeeksData = processedData.find((item) => item.date >= new Date(new Date().setDate(new Date().getDate() - 21)).toDateString());
            const last2WeeksData = processedData.find((item) => item.date >= new Date(new Date().setDate(new Date().getDate() - 14)).toDateString());
    
            const worstAverageYear = Math.min(lastYearData.sales[0], lastYearData.sales[1]);
            const bestAverageYear = Math.max(lastYearData.sales[0], lastYearData.sales[1]);
    
            const worstAverage5Weeks = Math.min(last5WeeksData.sales[0], last5WeeksData.sales[1]);
            const bestAverage5Weeks = Math.max(last5WeeksData.sales[0], last5WeeksData.sales[1]);
    
            const worstAverage3Weeks = Math.min(last3WeeksData.sales[0], last3WeeksData.sales[1]);
            const bestAverage3Weeks = Math.max(last3WeeksData.sales[0], last3WeeksData.sales[1]);
    
            const worstAverage2Weeks = Math.min(last2WeeksData.sales[0], last2WeeksData.sales[1]);
            const bestAverage2Weeks = Math.max(last2WeeksData.sales[0], last2WeeksData.sales[1]);
    
            setBestWorstSalesAcc({
              average_year: (bestAverageYear + worstAverageYear) / 2,
              average_5_weeks: (bestAverage5Weeks + worstAverage5Weeks) / 2,
              average_3_weeks: (bestAverage3Weeks + worstAverage3Weeks) / 2,
              average_2_weeks: (bestAverage2Weeks + worstAverage2Weeks) / 2
            })
        }
        function groupDataByDate(data) {
            return data.reduce((result, item) => {
              const date = new Date(item.month_start_date).toDateString();
              if (!result[date]) {
                result[date] = [];
              }
              result[date].push(item);
              return result;
            }, {});
          }
    
          function calculateAverage(data) {
            const totalSales = data.map((item) => parseFloat(item.total_sales));
            const sum = totalSales.reduce((total, count) => total + count, 0);
            return sum / totalSales.length;
          }
    }, [bestSalesData, worstSalesData])

    useEffect(() => {
        // calculate conversion rate for year, 5 weeks, 3 weeks, 2 weeks
        if (salesAvgData && customerAvgCountingData) {
            let dateyear = new Date().setFullYear(new Date().getFullYear() - 1)
            let date5weeks = new Date().setDate(new Date().getDate() - 35)
            let date3weeks = new Date().setDate(new Date().getDate() - 21)
            let date2weeks = new Date().setDate(new Date().getDate() - 14)
            const yearConversion = (((parseFloat(salesAvgData[0]?.average_sold_year ?? 0) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0)) / (customerAvgCountingData[0]?.average_count_year) ?? 0)*100)
            const fiveWeeksConversion = (((parseFloat(salesAvgData[0]?.average_sold_5_weeks ?? 0) / parseFloat(salesAvgData[0]?.average_basket_5_weeks ?? 0)) / (customerAvgCountingData[0]?.average_count_5_weeks) ?? 0)*100)
            const threeWeeksConversion = (((parseFloat(salesAvgData[0]?.average_sold_3_weeks ?? 0) / parseFloat(salesAvgData[0]?.average_basket_3_weeks ?? 0)) / (customerAvgCountingData[0]?.average_count_3_weeks) ?? 0)*100)
            const twoWeeksConversion = (((parseFloat(salesAvgData[0]?.average_sold_2_week ?? 0) / parseFloat(salesAvgData[0]?.average_basket_2_week ?? 0)) / (customerAvgCountingData[0]?.average_count_2_week) ?? 0)*100)
            const conversionRatedata = [
                {
                    date: dateyear,
                    conversion: yearConversion
                },
                {
                    date: date5weeks,
                    conversion: fiveWeeksConversion
                },
                {
                    date: date3weeks,
                    conversion: threeWeeksConversion
                },
                {
                    date: date2weeks,
                    conversion: twoWeeksConversion
                }
            ]
            setConversionRate(conversionRatedata)
        }
        console.log('useEffect [salesAvgData, customerAvgCountingData]')
    }, [salesAvgData, customerAvgCountingData])

    useEffect(() => {
        if (bestWorstOSAAcc && bestWorstTrafficAcc && bestWorstSalesAcc) {
            let dateyear = new Date().setFullYear(new Date().getFullYear() - 1)
            let date5weeks = new Date().setDate(new Date().getDate() - 35)
            let date3weeks = new Date().setDate(new Date().getDate() - 21)
            let date2weeks = new Date().setDate(new Date().getDate() - 14)
            const yearConversion = (((parseFloat(bestWorstSalesAcc?.average_year ?? 0) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0)) / (bestWorstTrafficAcc?.average_year) ?? 0)*100)
            const fiveWeeksConversion = (((parseFloat(bestWorstSalesAcc?.average_5_weeks ?? 0) / parseFloat(salesAvgData[0]?.average_basket_5_weeks ?? 0)) / (bestWorstTrafficAcc?.average_5_weeks) ?? 0)*100)
            const threeWeeksConversion = (((parseFloat(bestWorstSalesAcc?.average_3_weeks ?? 0) / parseFloat(salesAvgData[0]?.average_basket_3_weeks ?? 0)) / (bestWorstTrafficAcc?.average_3_weeks) ?? 0)*100)
            const twoWeeksConversion = (((parseFloat(bestWorstSalesAcc?.average_2_weeks ?? 0) / parseFloat(salesAvgData[0]?.average_basket_2_week ?? 0)) / (bestWorstTrafficAcc?.average_2_weeks) ?? 0)*100)
            const conversionRatedata = [
                {
                    date: dateyear,
                    conversion: yearConversion
                },
                {
                    date: date5weeks,
                    conversion: fiveWeeksConversion
                },
                {
                    date: date3weeks,
                    conversion: threeWeeksConversion
                },
                {
                    date: date2weeks,
                    conversion: twoWeeksConversion
                }
            ]
            setBestWorstConversionRate(conversionRatedata)
            console.log('conversionRatedata', conversionRatedata)
        }
    }, [bestWorstOSAAcc, bestWorstTrafficAcc, bestWorstSalesAcc])

  return (
    <>
    <div className="flex flex-col bg-gray-700 h-full">
    <Sidebar/>
    <main className="flex-grow ml-48 relative h-screen">
        <section className='p-8 bg-gray-700'>
            <div className="w-full flex flex-col bg-gray-700">
                <h1 className="text-2xl text-white mb-8">Results</h1>
                <div className="flex w-full gap-2 mb-4">
                <CalculationBox
                        title='On Shelf Availability'
                        children={[
                            {
                                title: 'Revenue last year (equally divided across stores)',
                                calculation: [],
                                result: userData?.average_revenue_per_store ?? 'N/A'
                            },
                            {
                                title: 'Total Revenue',
                                calculation: [userData?.active_stores ?? 'N/A', 'x', userData?.average_revenue_per_store ?? 'N/A'],
                                result: parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0)
                            },
                            {
                                title: 'Sales Uplift',
                                calculation: [parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0), 'x', userData?.osa_sales_uplift ?? 'N/A'],
                                result: (parseFloat(userData?.active_stores ?? 0) * parseFloat(userData?.average_revenue_per_store ?? 0)) * (parseFloat(userData?.osa_sales_uplift ?? 0)/100)
                            }
                    ]} />
                    <div className="w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                        <AreaChartStandard data={osaData} userData={userData} type={'onshelf'} height={'100%'} />
                    </div>
                </div>
            </div>
            <h1 className="text-xl font-bold text-white mb-8">Assumptions</h1>
            <div className="flex flex-wrap gap-2 bg-gray-700">
                <AssumptionCard title='Sales Uplift' children={{value: userData?.osa_sales_uplift ?? 'N/A', units: '%', description: 'Sales uplift as a result of better OSA (refill / move)'}} />
                <AssumptionCard title='Active Stores' children={{value: userData?.active_stores ?? 'N/A', units: '/ stores', description: 'Number of active stores'}} />
                <AssumptionCard title='Average Revenue' children={{value: userData?.average_revenue_per_store ?? 'N/A', units: ' / €', description: 'Revenue for 501 stores in 2023 (equally divided across stores)'}} />
            </div>
        </section>
        <section className='w-full bg-gray-700 p-8'>
            <h1 className="text-xl text-white mb-8">On Shelf Availability</h1>
            <div className='flex gap-2'>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-sm text-white text-center mt-4">Average On Shelf Availability</h1>
                    <h1 className='text-white text-center text-xs mb-4'>per week</h1>
                    <LineChartStandard data={osaData} type='osa'/>
                </div>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-sm text-white text-center my-4">Best vs Worse Stores</h1>
                    {
                        bestData && worstData &&
                        <AreaChartOsa data={{best: bestData, worst: worstData}} type='osa' />
                    }
                </div>
            </div>
        </section>
        <section className='w-full bg-gray-700 p-8'>
            <h1 className='text-xl text-white mb-8'>Customer Traffic</h1>
            <div className='flex gap-2'>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-sm text-white text-center mt-4">Average Customer Traffic</h1>
                    <h1 className='text-white text-center text-xs mb-4'>per week</h1>
                    <LineChartStandard data={customerAvgCountingData} type='traffic'/>
                </div>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-sm text-white text-center my-4">Best vs Worse Stores</h1>
                    {
                        bestCustomerCountingData && worstCustomerCountingData &&
                        <AreaChartOsa data={{best: bestCustomerCountingData, worst: worstCustomerCountingData}} type='traffic'/>
                    }
                </div>
            </div>
        </section>
        <section className='w-full bg-gray-700 p-8'>
            <h1 className='text-xl text-white mb-8'>Sales</h1>
            <div className='flex gap-2'>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                <h1 className="text-sm text-white text-center mt-4">Average Sales</h1>
                <h1 className='text-white text-center text-xs mb-4'>per week</h1>
                    <LineChartStandard data={salesAvgData} type='sales'/>
                </div>
                <div className="w-1/2 border border-slate-500 bg-gray-800">
                    <h1 className="text-sm text-white text-center my-4">Best vs Worse Sales</h1>
                    {
                        bestSalesData && worstSalesData &&
                        <AreaChartOsa data={{best: bestSalesData, worst: worstSalesData }} type='sales'/>
                    }
                </div>
            </div>
        </section>
        <section className='w-full bg-gray-700 p-8 flex flex-col'>
            <div className="flex w-full gap-2 mb-4">
                {   
                    userData && osaData && customerAvgCountingData && salesAvgData ?
                    <>
                        <CalculationBox
                                title='Conversion Rate'
                                children={[
                                    {
                                        title: 'Average sales per week per store',
                                        calculation: [],
                                        result: (Math.round(salesAvgData[0]?.average_sold_year) ?? 'N/A')
                                    },
                                    {
                                        title: 'Average transactions per week per store',
                                        calculation: [(Math.round(salesAvgData[0]?.average_sold_year) ?? 'N/A'), '/', (salesAvgData[0]?.average_basket_year ?? 'N/A')],
                                        result: Math.round(parseFloat(Math.round(salesAvgData[0]?.average_sold_year) ?? 0) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0))
                                    },
                                    {
                                        title: 'Average traffic per week per store',
                                        calculation: [],
                                        result: (Math.round(customerAvgCountingData[0]?.average_count_year) ?? 'N/A')
                                    },
                                    {
                                        title: 'Conversion Rate',
                                        calculation: ['(', Math.round(Math.round(parseFloat(salesAvgData[0]?.average_sold_year ?? 0)) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0)), '/', (Math.round(customerAvgCountingData[0]?.average_count_year) ?? 'N/A'), ')', 'x', '100'],
                                        result: (((parseFloat(salesAvgData[0]?.average_sold_year ?? 0) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0)) / (customerAvgCountingData[0]?.average_count_year) ?? 0)*100)
                                    }
                            ]} />
                            <div className="w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                                <AreaChartStandard data={conversionRate} userData={userData} type='conversion' height={'100%'} />
                            </div>
                    </> :
                    <>
                        <div className='bg-gray-800 border border-slate-500 shadow rounded'>
                            {
                                !osaData && 
                                <p className='text-white'>On shelf Data is required for calculating these metrics</p>
                            }
                            {
                                !customerAvgCountingData && 
                                <p className='text-white'>Customer Counting Data is required for calculating these metrics</p>
                            }
                            {
                                !salesAvgData && 
                                <p className='text-white'>Sales Data is required for calculating these metrics</p>
                            }
                        </div>
                    </>
                }
            </div>
            <div className="flex w-full gap-2 mb-4">
                {   
                    userData && osaData && customerAvgCountingData && salesAvgData ?
                    <div className='flex w-full gap-2'>  
                        <div className='w-2/5 bg-gray-800 shadow rounded border border-slate-500'>
                            <AreaChartStandard data={bestworstConversionRate} userData={userData} type='conversion' height={'100%'}/>
                        </div>
                        <CalculationBox
                                title='Best vs Worst Stores Conversion Rate'
                                children={[
                                    {
                                        title: 'Average sales per week per store',
                                        calculation: [],
                                        result: (Math.round(bestWorstSalesAcc?.average_year) ?? 'N/A')
                                    },
                                    {
                                        title: 'Average transactions per week per store',
                                        calculation: [(Math.round(bestWorstSalesAcc?.average_year) ?? 'N/A'), '/', (salesAvgData[0]?.average_basket_year ?? 'N/A')],
                                        result: Math.round(parseFloat(Math.round(bestWorstSalesAcc?.average_year) ?? 0) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0))
                                    },
                                    {
                                        title: 'Average traffic per week per store',
                                        calculation: [],
                                        result: (Math.round(bestWorstTrafficAcc?.average_year) ?? 'N/A')
                                    },
                                    {
                                        title: 'Conversion Rate',
                                        calculation: ['(', Math.round(Math.round(parseFloat(salesAvgData[0]?.average_sold_year ?? 0)) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0)), '/', (Math.round(customerAvgCountingData[0]?.average_count_year) ?? 'N/A'), ')', 'x', '100'],
                                        result: (((parseFloat(bestWorstSalesAcc?.average_year ?? 0) / parseFloat(salesAvgData[0]?.average_basket_year ?? 0)) / (bestWorstTrafficAcc?.average_year) ?? 0)*100)
                                    }
                            ]}
                             />
                    </div> :
                    <>
                        <div className='bg-gray-800 border border-slate-500 shadow rounded'>
                            {
                                !osaData && 
                                <p className='text-white'>On shelf Data is required for calculating these metrics</p>
                            }
                            {
                                !customerAvgCountingData && 
                                <p className='text-white'>Customer Counting Data is required for calculating these metrics</p>
                            }
                            {
                                !salesAvgData && 
                                <p className='text-white'>Sales Data is required for calculating these metrics</p>
                            }
                            {
                                !conversionRate && 
                                <p className='text-white'>Conversion Rate Data is required for calculating these metrics</p>
                            
                            }
                        </div>
                    </>
                }
            </div>
                    {
                        osaData && conversionRate && bestWorstOSAAcc && bestworstConversionRate &&
                <div className='flex gap-2'>
                        <CalculationBox
                            title='Impact of OSA in Conversion Rate'
                            children={[
                                {
                                    title: 'Global Increase of OSA last year',
                                    calculation: [parseFloat(osaData[0]?.average_osa_2_week ?? 0)*100, '-', parseFloat(osaData[0]?.average_osa_year ?? 0) * 100],
                                    result: (parseFloat(osaData[0]?.average_osa_2_week ?? 0) * 100 - parseFloat(osaData[0]?.average_osa_year ?? 0)*100)
                                },
                                {
                                    title: 'Global Increase conversion rate last year',
                                    calculation: [conversionRate[3]?.conversion ?? 0, '-', conversionRate[0]?.conversion ?? 0],
                                    result: ((conversionRate[3]?.conversion ?? 0) - (conversionRate[0]?.conversion ?? 0))
                                },
                                {
                                    title: 'Increase of OSA in selected stores',
                                    calculation: [(bestWorstOSAAcc?.average_2_weeks ?? 0)*100, '-', (bestWorstOSAAcc?.average_year ?? 0)*100],
                                    result: (((bestWorstOSAAcc?.average_2_weeks ?? 0)*100) - ((bestWorstOSAAcc?.average_year ?? 0)*100))
                                },
                                {
                                    title: 'Average Conversion Rate',
                                    calculation: [(bestworstConversionRate[3]?.conversion ?? 0), '-', (bestworstConversionRate[0]?.conversion ?? 0)],
                                    result: ((bestworstConversionRate[3]?.conversion ?? 0) - (bestworstConversionRate[0]?.conversion ?? 0))
                                },
                            ]}
                        />
                    <div className="flex w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500 items-center justify-center">
                        <h1 className='text-white'> 1 point of increase in OSA improve conversion rate in {(((parseFloat(1 - (parseFloat(osaData[0]?.average_osa_2_week ?? 0) * 100 - parseFloat(osaData[0]?.average_osa_year ?? 0)*100)) * ((conversionRate[3]?.conversion ?? 0) - (conversionRate[0]?.conversion ?? 0))) + ((1 - (((bestWorstOSAAcc?.average_2_weeks ?? 0)*100) - ((bestWorstOSAAcc?.average_year ?? 0)*100))) * ((bestworstConversionRate[3]?.conversion ?? 0) - (bestworstConversionRate[0]?.conversion ?? 0)))) / 2).toFixed(2)} %</h1>
                    </div>
                </div>
                    }
        </section>
    </main>
    </div>
    </>
  )
}