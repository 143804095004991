import { Spin, message, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { useState, useContext } from "react";
import * as XLSX from 'xlsx';
import FetchingData from "./FetchingData";
import { UserContext } from "../Context"
import ExampleFile from "../sample_traffic_file.xlsx"

export default function TrafficData() {
    const { user } = useContext(UserContext);
    const [loading, setloading] = useState(false)
    const [uploadData, setUploadData] = useState()
    const [uploadError, setUploadError] = useState()
    const { Dragger } = Upload

    const toObjectKey = (key) => {
        const headerKey = String(key).trim().toLowerCase()
        if (key.match(/(Location|location|store|Store)/i)) {
          return 'location'
        }
        if (key.match(/(date|Date|time|Time|Timestamp|timestamp)/i)) {
          return 'date'
        }
        if (key.match(/(count|Count|CustomerCounting|customercounting|customer_counting|Custumer_Counting)/i)) {
          return 'count'
        }
        return headerKey
      }

    const beforeUpload = (file) => {
        setUploadData()
        setUploadError()
        setloading(true)
        console.log('beforeUpload')
        const isExcelFile =
          file.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel'
        if (!isExcelFile) {
            setUploadError('You can only upload an excel file (.xlsx or .xls)')
        }
        return isExcelFile ? isExcelFile : Upload.LIST_IGNORE
      }
    
    const onFileChange = (info) => {
        if (info.file.status === 'uploading') {
            info.file.status = 'done' //hack to prevent a bug in antd.
        } else if (info.file.status === 'done') {
            message.success(`${info.file.name} uploaded successfully`)
            console.log(`${info.file.name} uploaded successfully`)
        } else if (info.file.status === 'error') {
            setUploadError(`${info.file.name} upload failed`)
            console.log(`${info.file.name} upload failed`)
        }
    }

    const onLoadFile = async ({ file, onSuccess }) => {
        console.log('onLoadFile')
        var reader = new FileReader()
        reader.onload = async (e) => {
            var data = e.target.result
            var workbook = XLSX.read(data, {
              type: 'binary',
              cellDates: true
            })
            for (const wsName of workbook.SheetNames) {
                const sheet = workbook.Workbook.Sheets.find((sheet) => sheet.name === wsName)
                if (sheet.Hidden !== 0) {
                    console.log('Sheet is hidden', wsName)
                    continue
                }
                const ws = workbook.Sheets[wsName]
                let lines = XLSX.utils.sheet_to_json(ws, {
                     header: 1,
                     blankrows: false,
                     })
                // remove incomplete data
                const sanitizedLines = lines.map((row) => {
                    return row.filter((cell) => String(cell).trim !== '')
                })
                const lineschecked = sanitizedLines.filter((row) => {
                    if (row.length >= 3) {
                        return row
                    } else {
                        console.log('row discarded', row)
                    }
                })
                //find the header row
                let header = [
                    'Location',
                    'Date',
                    'Count'
                ]
                const headerRow = lineschecked.findIndex((row) => row.includes('Location' || 'location' || 'Store' || 'store'))
                if (headerRow === -1) {
                    setUploadError('No header row found')
                    continue
                }
                if (headerRow > -1) {
                    //header found,sanitize and use it.
                    header = lineschecked[headerRow].map((h) => toObjectKey(h))
                    //remove header from lines
                    lineschecked.splice(headerRow, 1)
                }
                //convert from 2d array to array of objects
                const linesWithHeader = lineschecked.map((row) =>
                    row.reduce((total, item, i) => ((total[header[i]] = item), total), {})
                )
                setUploadData({data: linesWithHeader})
            }
        }
        reader.readAsArrayBuffer(file)
        setloading(false)
        onSuccess('ok')
    }
return (
    <>
        <section>
            <h1 className='text-white text-xl mb-2'>Upload Customer Counting Data</h1>
            <div className='bg-gray-800 p-4 rounded'>
                <Dragger
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    accept={'.xlsx,.xls'}
                    customRequest={onLoadFile}
                    onChange={onFileChange}
                    loading={loading}
                >
                    <p className="text-gray-400 text-4xl">
                        {
                            loading ? <Spin /> : <InboxOutlined />
                        }
                    </p>
                    <p className="text-white">Click or drag file to this area to upload</p>
                    <p className="text-gray-400">
                        Only .xlsx and .xls files are allowed, you can download a sample file <a className="underline" href={ExampleFile} download>here</a>
                    </p>
                    {
                        uploadError && <p className="text-red-400">{uploadError}</p>
                    }
                </Dragger>
            </div>
            <div className="w-full flex justify-end bg-gray-800 rounded">
                <button className="text-[#82ca9d] px-2 mr-4 mb-4 rounded border border-[#82ca9d]" type='primary' disabled={!uploadData} onClick={() => FetchingData(`/api/customer-counting?organizationId=${user.organization_id}`, uploadData, 'POST')}>Upload</button>
            </div>
        </section>
        <style jsx="true">{`
            .ant-upload-list {
                color: white;
            }
            .anticon svg {
                color: white;
            }
        `}</style>
    </>
)
}